import React from 'react'
import Styled from 'styled-components'

const BurgerIcon = ({burger_class, iconColor}) => {
    return (
        <Wrapper $iconColor={iconColor} >
            <label className={burger_class}>
                <span className={burger_class}/>
            </label>
        </Wrapper>
    )
}

const Wrapper = Styled.label`

.burger-bar{
    display:flex;
    flex-direction:column;
    gap: var(--size-icon-gap);
    cursor:pointer;
    width: max-content;
    background-color:inherit;
}

.burger-bar::before,
.burger-bar::after,
.burger-bar span{
    content: "";
    width: var(--size-icon-width) ;
    height: var(--size-icon-height);
    background-color: ${props=> props.$iconColor};
    border-radius: 20px;
    transition: all 0.5s ease-in-out;
    transform-origin: right center ;
}
.clicked span{
    width:0;
    opacity: 0;
}

.clicked{
    &::before{
        rotate: -45deg;
        width: var(--x-width);
        translate: 0 calc(var(--size-icon-height) /-2);
    }
    &::after{
        rotate: 45deg;
        width: var(--x-width);
        translate: 0 calc(var(--size-icon-height) / 2);
    }
}

/* --------mediaquery-------- */

//---xs---
@media (min-width: 475px){

}

//---sm---
@media (min-width: 640px){
  
}

//---md---
@media (min-width: 768px){
}

//---lg---
@media (min-width: 1024px){
  
}

//---xl---
@media (min-width: 1330px){

} 

 //---2xl---
@media (min-width: 1770px){
  
}
`

export default BurgerIcon