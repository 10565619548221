import { Outlet } from "react-router-dom"
import Header from "../components/Header"
import Footer from "../components/Footer"

export const HomeTemplate = () => {
  return (
    <>
        <Header bgClr="transparent" iconColor="#E2E2E2" logoColor="#E2E2E2"></Header>
        <Outlet></Outlet>
        {/* <Footer></Footer> */}
    </>
  )
}
