import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import BurgerIcon from './BurgerIcon';

const Header = ({ bgClr, iconColor, logoColor }) => {
  const ref = useRef();
  const [burger_class, setBurgerClass] = useState("burger-bar unclicked");
  const [burgerMenuClass, setBurgerMenuClass] = useState("menuUnclicked");
  const [isMenuClicked, setIsMenuCLicked] = useState(false);

  const onClickBurgerIcon = () => {
    if (!isMenuClicked) {
      setBurgerClass("burger-bar clicked");
      setBurgerMenuClass("menuClicked");
    } else {
      setBurgerClass("burger-bar unclicked");
      setBurgerMenuClass("menuUnclicked");
    }
    setIsMenuCLicked(!isMenuClicked);
  }

  const onClicklogo = () => {
    if (isMenuClicked) {
      setBurgerClass("burger-bar unclicked");
      setBurgerMenuClass("menuUnclicked");
      console.log("Hello");
    }
  }

  return (
    <Wrapper ref={ref} $bgClr={isMenuClicked? "transparent" : bgClr} $logoColor={isMenuClicked?"#E2E2E2" : logoColor} className='container'>
      <header className='header container containerLR'>
        <nav className='flexRSE'>
          <NavLink to='/'>
            <h4 className='header__logo' onClick={onClicklogo}>with the dragonflies</h4>
          </NavLink>
          <span className='header__burger' onClick={onClickBurgerIcon}>
            <BurgerIcon burger_class={burger_class} iconColor={isMenuClicked? "#E2E2E2" : iconColor} />
          </span>
        </nav>
        <div className={`burger-menu-class containerInnerPad flexCCC ${burgerMenuClass}`}>
          <div className='header__menu flexCCC'>
            <ul className='flexCCC'>
              <li>
                <NavLink className='nav-links' to="/projects" onClick={onClickBurgerIcon}><h5>projects</h5></NavLink>
              </li>
              <li>
                <NavLink className='nav-links' to="/practices" onClick={onClickBurgerIcon}><h5>practice</h5></NavLink>
              </li>
              <li>
                <NavLink className='nav-links' to="/open_source" onClick={onClickBurgerIcon}><h5>open source | DIY</h5></NavLink>
              </li>
              <li>
                <NavLink className='nav-links' to="/contact" onClick={onClickBurgerIcon}><h5>contact</h5></NavLink>
              </li>
            </ul>
          </div>
        </div>
      </header>
    </Wrapper>
  )
}

const Wrapper = styled.header`
.header{
  transition: all 0.5ms ease-in;
  position: fixed;
  top: 0;
  z-index: 100;
  background-color: ${props => props.$bgClr};
}
nav{  
  height: 2rem;
  background-color: ${props => props.$bgClr};
  a{
      text-decoration: none;
  }
  .header__logo{
    font-size: 0.9rem;
    margin-bottom: -7px;
    /* color: var(--clr-dark); */
    color: ${props => props.$logoColor};
    span{
      display: none;
    }
  }
}

.burger-menu-class{
  width: 100%;
  position: absolute;
  border-radius: 2px;
  top: 0;
  left: 0;
  height: 100vh;
  z-index: -1;
  .header__menu{
    height: 100%;
    width: 100%;
    text-align: center;
    background-color: rgba(45,45,45, 0.95);
  }
  
  &.menuUnclicked{
    opacity:0;
    transform: scale(0);
    transition: opacity 0.2s ease-in-out, transform 2.5s ease-out;

  }
  
  &.menuClicked{
    opacity:1;
    transform: scale(1);
    transition: opacity 0.3s ease-in-out;
  }
  .header__menu{
    ul{
      list-style-type: none;
      gap: 2rem;
    }
    li{
      text-decoration: none;
      a{
      text-decoration: none;
      }
      &{
        position: relative;
        transition: translate 0.5s ease-in-out;
        color: var(--clr-light);
        cursor: pointer;
        &:hover::after{
          transition: all 0.5s ease-in-out;
          transform: scaleX(1);
        }
        &::after{
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          height: 0.05rem;
          width: 100%;
          background-color: var(--clr-light);
          transform: scaleX(0);
        } 
      }
      h5{
        font-weight:600;
      }
    }  
  }
}


//---xs---
@media (min-width: 550px){
  nav{
    height: 3rem;
  }
}

//---sm---
@media (min-width: 640px){
  
}

//---md---
@media (min-width: 768px){
  nav{
    height: 4rem;
    .header__logo{
      font-size: 1.2rem;
    }
  }
}

//---lg---
@media (min-width: 1024px){
  nav{
    height: 4rem;
    .header__logo{
      font-size: 1.2rem;
      font-weight: 600;
    }
  }
}

//---xl---
@media (min-width: 1280px){

}

 //---2xl---
@media (min-width: 1536px){
  
}

`

export default Header