import React from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

const Footer = () => {
    return (
        <FooterWrapper className='container flexCCC'>
            <section className='containerLR'><hr /></section>
            <div className="footer flexCCC">
                <div className="footer_links">
                    <ul className='footer_links_ul flexRCC'>
                        <li className='footer_links_ul_li'>
                            <NavLink className='nav-links' to="/projects"><h5>projects</h5></NavLink>
                        </li>
                        <li className='footer_links_ul_li'>
                            <NavLink className='nav-links' to="/practices"><h5>practice</h5></NavLink>
                        </li>
                        <li className='footer_links_ul_li'>
                            <NavLink className='nav-links' to="/open_source">
                                <div className='flexRCC'>
                                    <div className='openSource'>
                                        <p>open</p>
                                        <p className='secondP'>source</p>
                                    </div>
                                    <h5>|</h5>
                                    <h5>diy</h5>
                                </div>
                            </NavLink>
                        </li>
                        <li className='footer_links_ul_li'>
                            <NavLink className='nav-links' to="/contact"><h5>contact</h5></NavLink>
                        </li>
                    </ul>
                </div>
                <div className="footer_contents flexCCC">
                    <div className="logo">
                        <img src="wtd_assets/logo_footer.png" alt="logo" />
                    </div>
                    <div className="contact_info flexCCC">
                        <h6 className='wdps'>WTD Design & planning services</h6>
                        <h6>withethedragonflies@gmail.com</h6>
                        <h6>+91 8867280145</h6>
                        <h6>Bengaluru</h6>
                        <h6>India</h6>
                    </div>
                    <div>
                        <h6>WTD © 2023</h6>
                    </div>
                    <div className='designer_signature flexRCC'>
                        <span>Made by</span>
                        <h6><a href="https://www.instagram.com/cleanstrap/" target='_blank'>Cleanstrap</a></h6>
                    </div>
                </div>
            </div>
        </FooterWrapper>
    )
}

const FooterWrapper = styled.div`
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    gap: 1.2rem;
    hr{
        width: 100%;
        color: var(--clr-helper);
        opacity: 0.5;
    }
    a{
        text-decoration: none;
    }

    p{
        font-size: 0.5rem;
        color: var(--clr-dark);
    }

    h5{
        font-size: 0.85rem;
        font-weight: 500;
        color: var(--clr-dark);
        position: relative;
            &:hover::after{
            transition: all 0.5s ease-in-out;
            transform: scaleX(1);
            }
            &::after{
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            height: 0.05rem;
            width: 100%;
            background-color: var(--clr-dark);
            transform: scaleX(0);
            } 
    }

    h6{
        font-weight: 500;
        font-size: 0.65rem;
        color: var(--clr-dark);
    }

    span{
        font-weight: 400;
        font-size: 0.45rem;
        color: var(--clr-dark); 
    }

    .footer{
        gap: 2rem;
    }

    .footer_links{
        .footer_links_ul{
            list-style-type: none;
            gap: 1.5rem;
            .footer_links_ul_li{
                a{
                    text-decoration: none;
                }
                .openSource{
                    p{
                        text-align: right;
                        margin-bottom: 1px;
                        
                    }
                    .secondP{
                        margin-top: -9px;
                    }
                }
            }
        }
    }

    .footer_contents{
        gap: 0.8rem;
        .logo{
            aspect-ratio: 1;
            width: 8rem;
            img{
                width: 100%;
                height: 100%;
            }
        }
        .contact_info{
            gap: 0.2rem;
            .wdps{
                color: var(--clr-box);
                font-weight: 700;
            } 
        }
        .designer_signature{
            gap: 0.2rem;
            h6{
                font-weight: 600;
                font-size: 0.6rem;
            }
        }
    }

    @media (min-width: 550px) {
        padding-top: 2rem;
        padding-bottom: 2rem;
        gap: 2.2rem;
        p{
            font-size: 0.55rem;
            font-weight: 500;
        }

        h5{
            font-size: 1rem;
            font-weight: 600;
        }

        h6{
            font-weight: 500;
            font-size: 0.7rem;
        }

        span{
            font-weight: 400;
            font-size: 0.5rem;
        }

        .footer{
            gap: 3rem;
        }

        .footer_links{
        .footer_links_ul{
            gap: 4rem;
            .footer_links_ul_li{
                a{
                    text-decoration: none;
                }
                .openSource{
                    .secondP{
                        margin-top: -7px;
                    }
                }
            }
        }}

        .footer_contents{
            gap: 1rem;
            .logo{
                aspect-ratio: 1;
                width: 9rem;
            }
            .contact_info{
                gap: 0.2rem;
                .wdps{
                    color: var(--clr-box);
                    font-weight: 700;
                } 
            }
            .designer_signature{
                gap: 0.2rem;
                h6{
                    font-weight: 600;
                    font-size: 0.6rem;
                }
            }
        }
        }

    @media (min-width: 768px) {
        padding-top: 3rem;
        padding-bottom: 3rem;
        gap: 3.5rem;
        p{
            font-size: 0.7rem;
            font-weight: 600;
        }

        h5{
            font-size: 1.4rem;
            font-weight: 600;
           
        }

        h6{
            font-size: 0.9rem;
        }

        span{
            font-size: 0.6rem;
        }

        .footer{
            gap: 5rem;
        }

        .footer_links{
        .footer_links_ul{
            gap: 4rem;
            .footer_links_ul_li{
                a{
                    text-decoration: none;
                }
                .openSource{
                    .secondP{
                        margin-top: -7px;
                    }
                }
            }
        }}

        .footer_contents{
            gap: 1.5rem;
            .logo{
                width: 10rem;
            }
            .contact_info{
                gap: 0.3rem;
            }
            .designer_signature{
                gap: 0.2rem;
                h6{
                    font-weight: 700;
                    font-size: 0.8rem;
                }
            }
        }
    }

    @media (min-width: 1024px) {
        h5{
            font-size: 1.5rem;
            font-weight: 600;
        }
        p{
            font-size: 0.9rem;
        }
        span{
            font-size: 0.55rem;
        }

        .footer{
            gap: 5.5rem;
        }

        .footer_links{
            margin-left: -17px;
        .footer_links_ul{
            gap: 7rem;
        }}

        .footer_contents{
            gap: 1.5rem;
            .logo{
                width: 10.5rem;
            }
            .designer_signature{
                gap: 0.2rem;
                h6{
                    font-weight: 700;
                    font-size: 0.7rem;
                }
            }
        }
    }

`

export default Footer