import { createGlobalStyle } from "styled-components";
// import HelveticaNueueMedium from "./assets/helvetica-neue-55-cufonfonts/HelveticaNeueMedium.ttf"
// import HelveticaNueueMedium from "./assets/helvetica-neue-55-cufonfonts/HelveticaNeue-Thin.otf" 

export const GlobalStyle = createGlobalStyle`

  ${'' /* @font-face {
        font-family: 'Helvetica Nueue Medium';
        src: url(${HelveticaNueueMedium}) format('truetype');
        src: url(${HelveticaNueueMedium}) format('opentypefont');
  } */}
   
   :root{

    --clr-dark: #2D2D2D;
    --clr-light: #E2E2E2;
    --clr-icon: #878787;
    --clr-helper:#878787;
    --clr-box: rgba(113, 113, 113, 0.80);
    --clr-footer: #161616;
    --clr-homeText: #57534e;
    --clr-homeTextSpan:#0a0a0a;

    //----------global sizes------------

    //============COMPONENTS=============

    //------------grid gaps on cards----------------

    --grid-gap: 0.8rem;
    --home-lr-padding:0.5rem;
    --home-top-padding:1rem;
    --home-grid-gap:0.5rem;

    //--------header---------
    --size-header-height: calc(50px + (130 - 50) * (100vw - 320px) / (1920 - 320));
    
    //--------burger icon---------------
    --size-icon-height: calc(2.4px + (3 - 2.4) * (100vw - 320px) / (1920 - 320));
    --size-icon-width: calc(24px + (29 - 24) * (100vw - 320px) / (1920 - 320));
    --size-icon-gap: calc(3px + (6 - 3) * (100vw - 320px) / (1920 - 320));
    --size-icon-rad: calc(var(--size-icon-height) * 3 + var(--size-icon-gap) * 2);
    --x-width: calc(var(--size-icon-rad) * 1.41421356237);


    //===============PAGES================

   }

  *{
        margin: 0;
        box-sizing: border-box;
        line-height: calc(1em + 0.5rem);
        ${'' /* font-family: 'Helvetica Nueue Medium'; */}
        font-family: 'Inter', sans-serif;
        padding: 0;
        color: var(--clr-light);
    }

    body{
      background-color: white;
    }

    html{
      scroll-behavior: smooth;
    }

    a{
      color: var(--clr-dark);
    }



    .container{
        width:100%;
    }

    .containerLR{
        width:100%;
        padding-left:0.8rem;
        padding-right:0.8rem;
    }

    .containerMain{
        width:100%;
        margin-top: 8rem;
        margin-bottom: 5rem;
    }

    .containerLast{
        width:100%;
        margin-top: 5rem;
        margin-bottom: 5rem;
    }

    .flex{
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .flexRCC{
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .flexRSE{
      display: flex;
      justify-content: space-between;
      align-items: end;
    }
    .flexREC{
      display: flex;
      justify-content: end;
      align-items: center;
    }

    .flexCCC{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .flexCLL{
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: start;
    }

    .grid{
      display: grid;

    }


    //-----responsive fontsize => min-font-px + (max-font - min-font) * (100vw - min-resolution-px) / (max-resolution - min resolution)
    h1{
      font-size: 2.5rem;
      font-weight: 700;
    }

    h4{
      font-size: 1.1rem;
      font-weight: 500;
    }

    h5{
      font-size: 0.9rem;
      font-weight: 400;
    }
    h6{
      font-size: 0.75rem;
      font-weight: 200;
    }

    p{
      font-size: 0.75rem;
    }


    /*-------------------container & media queries--------------------------*/
   //---xs---

  //---sm---
  @media (min-width: 550px){
    :root{
      --grid-gap: 1.5rem;
      --home-lr-padding:0.7rem;
      --home-grid-gap:0.7rem;
    }
    .containerMain{
      margin-top: 10.5rem;
      margin-bottom: 8rem;
    }
    .containerLR{
      padding-left:1.5rem;
      padding-right:1.5rem;
    }
    h1{
      font-size: 4.5rem;
      font-weight: 700;
    }
    h4{
      font-size: 1.3rem;
      font-weight: 600;
    }
    h5{
      font-size: 1.1rem;
      font-weight: 300;
    }
    h6{
      font-size: 1rem;
      font-weight: 300;
    }
    p{
      font-size: 1rem;
    }
  }

  //---md---
  @media (min-width: 768px){
    :root{
      --grid-gap: 1.2rem;
      --home-lr-padding:1.4rem;
      --home-top-padding:2.1rem;
      --home-grid-gap:1.4rem;
    }
    .containerMain{
      margin-top: 12rem;
      margin-bottom: 8rem;
    }
    .containerLast{
        width:100%;
        margin-top: 7rem;
        margin-bottom: 5rem;
    }
    .containerLR{
      padding-left:2rem;
      padding-right:2rem;
    }
  }

  //---lg---
  @media (min-width: 1024px){
    :root{
      --grid-gap: 3rem;
      --home-lr-padding:3.5rem;
      --home-top-padding:3.5rem;
      --home-grid-gap:3rem;
    }
    .containerMain{
      margin-top: 15rem;
      margin-bottom: 5rem;
    }
    .containerLR{
      padding-left:4.5rem;
      padding-right:4.5rem;
    }
    h1{
      font-size: 5rem;
      font-weight: 700;
    }
    h4{
      font-size: 1.3rem;
      font-weight: 500;
    }
    h5{
      font-size: 1.1rem;
      font-weight: 300;
    }
    h6{
      font-size: 0.9rem;
    }
    p{
      font-size: 1rem;
      font-weight: 300;
    }
  }

  //---xl---
  @media (min-width: 1280px){
    
  }

  //---2xl---
  @media (min-width: 1700px){
    .container{
      max-width: 1700px;
      margin-left: auto;
      margin-right: auto;
    }
  } 




`
