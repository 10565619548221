import { GlobalStyle } from './GlobalStyles'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React, { Fragment, useEffect, useState, lazy, Suspense } from 'react';
import SplashScreen from './components/SplashScreen';
import { HomeTemplate } from './outlets/HomeTemplate';
import { GlobalTemplate } from './outlets/GlobalTemplate';
import { Template } from './outlets/Template';
import Loader from './components/Loader';

const Home = lazy(() => import('./pages/Home/Home'))
const Projects = lazy(() => import('./pages/Projects/Projects'))
const Description = lazy(() => import('./pages/Projects/Description'))
const Practices = lazy(() => import('./pages/Practices/Practices'))
const PDragonflies = lazy(() => import('./pages/Practices/PDragonflies'))
const OpenSource = lazy(() => import('./pages/OpenSource/OpenSource'))
const Contact = lazy(() => import('./pages/Contact/Contact'))
const ErrorPage = lazy(() => import('./pages/ErrorPage'))
const PHabitat = lazy(() => import('./pages/Practices/PHabitat'))
const PInspiration = lazy(() => import('./pages/Practices/PInspiration'))

function App() {

  const [showMainContent, setShowMainContent] = useState(false);
  const [showSplashScreen, setSplashScreen] = useState(true);
  
  useEffect(() => {
    let splashScreenVal = sessionStorage.getItem("splashScreen")
    if(splashScreenVal === "true"){
      setSplashScreen(false)
      setShowMainContent(true)
    }else{
      setSplashScreen(true)
      setTimeout(() => {
        setShowMainContent(true)
        sessionStorage.setItem("splashScreen", "true")
      }, 4000)
    }
  }, [])

  return (
    <Fragment>
      <GlobalStyle />
      {showSplashScreen && (<SplashScreen />)}
      {showMainContent && (
        <Router>
          <Suspense fallback={<Loader></Loader>}>
            <Routes>
              <Route path='/' element={<HomeTemplate />}>
                <Route path='/' element={<Home />} />
              </Route>
              <Route path='/' element={<GlobalTemplate />}>
                <Route path="/projects" element={<Template />}>
                  <Route index element={<Projects />} />
                  <Route path="description" element={<Description />} />
                </Route>
                <Route path="/practices" element={<Template />}>
                  <Route index element={<Practices />} />
                  <Route path='dragonflies' element={<PDragonflies />} />
                  <Route path='inspiration' element={<PInspiration />} />
                  <Route path='ourHabitat' element={<PHabitat />} />
                </Route>
                <Route path="/open_source" element={<Template />}>
                  <Route index element={<OpenSource />} />
                  <Route path="description" element={<Description />} />
                </Route>
                <Route path="/contact" element={<Contact />} />
              </Route>
              <Route path="/*" element={<ErrorPage />} />
            </Routes>
          </Suspense>
        </Router>
      )}
    </Fragment>
  );
}

export default App;
