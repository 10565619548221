import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

const SplashScreen = () => {

  const [screenVisible, setScreenVisible] = useState('screen flex container');
  useEffect(() => {
    setTimeout(() => {
      setScreenVisible('screen flex container decOpacity')
    }, 3000)
    setTimeout(() => {
      setScreenVisible('screen flex container invisible')
    }, 5000)
  }, [])

  return (
    <Wrapper>
      <div className={screenVisible}>
          <section className='logo'>
            <img src='wtd_assets/logo_footer.png' alt='logo' loading='lazy'></img>
          </section>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  
.screen {
  position: absolute;
  height: 100vh;
  flex-direction: column;
  background-color: rgba(255,255,255, 1);
  z-index: 9999;
  img{
    aspect-ratio: 1;
    width: 12rem;
    object-fit: contain;
  } 
}

@media (min-width: 1024px) {
  .screen{
    img{
      width: 14rem;
    }
  }
}


.visible{
  opacity: 1;
  transition: all 2s ease-in-out;
}

.decOpacity{
  img{
    opacity: 0;
    transition: all 2s ease-in-out;
  }
  .subtitle{
    opacity: 0;
    transition: all 2s ease-in-out;
  }
  background-color: rgba(255,255,255, 0.8);
  transition: all 3s ease-in-out;
}

.invisible{
  display: none;
}


`

export default SplashScreen