import { Outlet } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer"

export const GlobalTemplate = () => {
  return (
    <>
        <Header bgClr="white" iconColor="#2D2D2D" logoColor="#2D2D2D"></Header>
        <Outlet></Outlet>
        <Footer></Footer>
    </>
  )
}
